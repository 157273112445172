import { observer } from "mobx-react-lite";

import { etBlack, etGray, etWhite } from "../../shared/colors";
import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { ContextMenuCardDelete } from "../CardSimpleContext/ContextMenuCardDelete";
import ContextMenuCardEdit from "../CardSimpleContext/ContextMenuCardEdit";
import { ContextMenuCardOrder } from "../CardSimpleContext/ContextMenuCardOrder";
import { ContextMenuCardReport } from "../CardSimpleContext/ContextMenuCardReport";
import { IconSvg } from "../IconSvg/IconSvg";
import { sc } from "./CardItemInfoContextMenu.styled";
import { EtCircleColorPicker } from "./EtCircleColorPicker";

export interface CardInfoContextMenuDriver {
  isGuestView: boolean;
  isCardSizeVisible: boolean;
  contentType: CardType.INFO;

  cardSize: CardSize;
  onCardSizeChanged(size: CardSize): void;

  textAlign: CardTextAlignment;
  onTextAlignmentChanged(textAlign: CardTextAlignment): void;

  textColor: CardTextColor;
  onTextColorChanged(textColor: CardTextColor): void;

  backgroundColor: string;
  onBackgroundColorChanged(value: string): void;

  shouldRenderCardOrder: boolean;
  cardOrderInput: string | null;
  sortNumberInputError: string | null;
  onCardOrderChanged(order: string): void;
  onCardOrderBlured(): void;
  onCardEditButtonClicked(): void;
  onCardDeleteButtonClicked(): void;
  onCardReportButtonClicked(): void;

  // This prop is using for storybook only
  hoveringItem?:
    | "CARD_SIZE"
    | "TEXT_ALIGN"
    | "TEXT_COLOR"
    | "BG_COLOR"
    | "MENU_ITEM"
    | "DELETE"
    | "REPORT";
}
interface IProps {
  driver: CardInfoContextMenuDriver;
}

export const CardItemInfoContextMenu = observer(
  function CardItemInfoContextMenu(props: IProps) {
    const {
      isGuestView,
      isCardSizeVisible,
      cardSize,
      onCardSizeChanged,
      textAlign,
      onTextAlignmentChanged,
      textColor,
      onTextColorChanged,
      backgroundColor,
      onBackgroundColorChanged,
      shouldRenderCardOrder,
      cardOrderInput,
      sortNumberInputError,
      onCardOrderChanged,
      onCardOrderBlured,
      onCardEditButtonClicked,
      onCardDeleteButtonClicked,
      onCardReportButtonClicked,
      hoveringItem,
    } = props.driver;
    const { t } = useTranslation("CardItemInfo");

    const hoveredColorIndex = hoveringItem === "BG_COLOR" ? 6 : undefined; // For visual test only

    return isGuestView ? (
      <sc.Container
        data-cy="CardItemInfoContextMenu-Container"
        className="visual-reg"
      >
        <sc.MenuItem data-cy="CardItemInfoContextMenu-MenuItemCardDelete">
          <ContextMenuCardReport
            onReport={onCardReportButtonClicked}
            isHovering={hoveringItem === "REPORT"}
          />
        </sc.MenuItem>
      </sc.Container>
    ) : (
      <sc.Container
        data-cy="CardItemInfoContextMenu-Container"
        className="visual-reg"
      >
        {isCardSizeVisible && (
          <>
            <sc.CardSizeContainer>
              <sc.BlockTitle data-cy="CardItemInfoContextMenu-CardSize">
                {t("context-menu.card-size.title")}
              </sc.BlockTitle>
              <sc.CardSizeItems>
                <sc.CardSizeItem
                  data-cy="CardItemInfoContextMenu-CardSizeSingle"
                  onClick={() => onCardSizeChanged(CardSize.SINGLE)}
                >
                  <sc.IconBorder
                    shouldChangeIconColorWhenHover={true}
                    className={cardSize === CardSize.SINGLE ? `active` : ""}
                  >
                    <IconSvg
                      icon="single-card"
                      width="32px"
                      height="32px"
                      padding="0"
                      color={etGray}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.CardSizeItemText>
                    {t("context-menu.card-size.single")}
                  </sc.CardSizeItemText>
                </sc.CardSizeItem>
                <sc.CardSizeItem
                  data-cy="CardItemInfoContextMenu-CardSizeDouble"
                  onClick={() => onCardSizeChanged(CardSize.DOUBLE)}
                >
                  <sc.IconBorder
                    shouldChangeIconColorWhenHover={true}
                    className={cardSize === CardSize.DOUBLE ? "active" : ""}
                  >
                    <IconSvg
                      icon="double-card"
                      width="32px"
                      height="32px"
                      padding="0"
                      color={etGray}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.CardSizeItemText>
                    {t("context-menu.card-size.double")}
                  </sc.CardSizeItemText>
                </sc.CardSizeItem>
                <sc.CardSizeItem
                  data-cy="CardItemInfoContextMenu-CardSizeTriple"
                  onClick={() => onCardSizeChanged(CardSize.TRIPLE)}
                >
                  <sc.IconBorder
                    shouldChangeIconColorWhenHover={true}
                    className={
                      cardSize === CardSize.TRIPLE ||
                      hoveringItem === "CARD_SIZE"
                        ? `active`
                        : ""
                    }
                  >
                    <IconSvg
                      icon="triple-card"
                      width="32px"
                      height="32px"
                      padding="0"
                      color={etGray}
                      fillColor={etGray}
                      pointer
                    />
                  </sc.IconBorder>
                  <sc.CardSizeItemText>
                    {t("context-menu.card-size.triple")}
                  </sc.CardSizeItemText>
                </sc.CardSizeItem>
              </sc.CardSizeItems>
            </sc.CardSizeContainer>
            <sc.EditTextContainer>
              <sc.TextAlignment>
                <sc.BlockTitle data-cy="CardItemInfoContextMenu-TextAlign">
                  {t("context-menu.text-alignment.title")}
                </sc.BlockTitle>
                <sc.TextAlignmentItems>
                  <sc.TextAlignmentItem
                    data-cy="CardItemInfoContextMenu-TextAlignLeft"
                    onClick={() =>
                      onTextAlignmentChanged(CardTextAlignment.LEFT)
                    }
                  >
                    <sc.IconBorder
                      shouldChangeIconColorWhenHover={true}
                      className={
                        textAlign === CardTextAlignment.LEFT ? `active` : ""
                      }
                    >
                      <IconSvg
                        icon="text-align-left"
                        width="16px"
                        height="16px"
                        padding="0"
                        color={etGray}
                        pointer
                      />
                    </sc.IconBorder>
                    <sc.TextAlignmentItemText>
                      {t("context-menu.text-alignment.left")}
                    </sc.TextAlignmentItemText>
                  </sc.TextAlignmentItem>
                  <sc.TextAlignmentItem
                    data-cy="CardItemInfoContextMenu-TextAlignCenter"
                    onClick={() =>
                      onTextAlignmentChanged(CardTextAlignment.CENTER)
                    }
                  >
                    <sc.IconBorder
                      shouldChangeIconColorWhenHover={true}
                      className={
                        textAlign === CardTextAlignment.CENTER ||
                        hoveringItem === "TEXT_ALIGN"
                          ? `active`
                          : ""
                      }
                    >
                      <IconSvg
                        icon="text-align-center"
                        width="16px"
                        height="16px"
                        padding="0"
                        color={etGray}
                        pointer
                      />
                    </sc.IconBorder>
                    <sc.TextAlignmentItemText>
                      {t("context-menu.text-alignment.center")}
                    </sc.TextAlignmentItemText>
                  </sc.TextAlignmentItem>
                </sc.TextAlignmentItems>
              </sc.TextAlignment>
              <sc.TextColor>
                <sc.BlockTitle data-cy="CardItemInfoContextMenu-TextColor">
                  {t("context-menu.text-color.title")}
                </sc.BlockTitle>
                <sc.TextColorItems>
                  <sc.TextColorItem
                    data-cy="CardItemInfoContextMenu-TextColorWhite"
                    onClick={() => onTextColorChanged(CardTextColor.WHITE)}
                  >
                    <sc.IconBorder
                      className={
                        textColor === CardTextColor.WHITE ? `active` : ""
                      }
                    >
                      <sc.IconWrapper>
                        <IconSvg
                          icon="a-black"
                          width="16px"
                          height="16px"
                          padding="0"
                          fillColor={etWhite}
                          pointer
                        />
                      </sc.IconWrapper>
                    </sc.IconBorder>
                    <sc.TextColorItemText>
                      {t("context-menu.text-color.white")}
                    </sc.TextColorItemText>
                  </sc.TextColorItem>
                  <sc.TextColorItem
                    data-cy="CardItemInfoContextMenu-TextColorBlack"
                    onClick={() => onTextColorChanged(CardTextColor.BLACK)}
                  >
                    <sc.IconBorder
                      className={
                        textColor === CardTextColor.BLACK ||
                        hoveringItem === "TEXT_COLOR"
                          ? `active`
                          : ""
                      }
                    >
                      <IconSvg
                        icon="a-black"
                        width="16px"
                        height="16px"
                        padding="0"
                        fillColor={etBlack}
                        pointer
                      />
                    </sc.IconBorder>
                    <sc.TextColorItemText>
                      {t("context-menu.text-color.black")}
                    </sc.TextColorItemText>
                  </sc.TextColorItem>
                </sc.TextColorItems>
              </sc.TextColor>
            </sc.EditTextContainer>
            <sc.BackgroundColorPickerContainer>
              <EtCircleColorPicker
                color={backgroundColor}
                renderLabel={() => {
                  return (
                    <sc.BlockTitle data-cy="CardItemInfoContextMenu-BackgroundColor">
                      {t("context-menu.background-color.title")}
                    </sc.BlockTitle>
                  );
                }}
                onChange={onBackgroundColorChanged}
                hoveredColorIndex={hoveredColorIndex}
              />
            </sc.BackgroundColorPickerContainer>
          </>
        )}
        {shouldRenderCardOrder && (
          <sc.MenuItem
            data-cy="CardItemInfoContextMenu-MenuItemCardEdit"
            useDefaultCursor={true}
          >
            <ContextMenuCardOrder
              sortNumber={cardOrderInput || ""}
              onChanged={onCardOrderChanged}
              onBlured={onCardOrderBlured}
              sortNumberInputError={sortNumberInputError}
            />
          </sc.MenuItem>
        )}
        <sc.MenuItem data-cy="CardItemInfoContextMenu-MenuItemCardEdit">
          <ContextMenuCardEdit
            onEdit={onCardEditButtonClicked}
            isHovering={hoveringItem === "MENU_ITEM"}
          />
        </sc.MenuItem>
        <sc.MenuItem data-cy="CardItemInfoContextMenu-MenuItemCardDelete">
          <ContextMenuCardDelete
            onDelete={onCardDeleteButtonClicked}
            isHovering={hoveringItem === "DELETE"}
          />
        </sc.MenuItem>
      </sc.Container>
    );
  },
);
