import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import {
  CardArticleLayoutType,
  CardSize,
  CardType,
  MasterLinkType,
} from "../../shared/models/Card";
import {
  CardItemAction,
  CardItemActionDriver,
} from "../CardItemAction/CardItemAction";
import {
  CardItemArticle,
  CardItemArticleDriver,
} from "../CardItemArticle/CardItemArticle";
import {
  CardItemDraft,
  CardItemDraftDriver,
} from "../CardItemDraft/CardItemDraft";
import {
  CardItemEditable,
  CardItemEditableDriver,
} from "../CardItemEditable/CardItemEditable";
import { CardItemInfo, CardItemInfoDriver } from "../CardItemInfo/CardItemInfo";
import {
  CardItemMusic,
  CardItemMusicDriver,
} from "../CardItemMusic/CardItemMusic";
import {
  CardItemPromotion,
  CardItemPromotionDriver,
} from "../CardItemPromotion/CardItemPromotion";
import {
  CardItemVideo,
  CardItemVideoDriver,
} from "../CardItemVideo/CardItemVideo";
import { CardRemovedContent } from "../CardRemovedContent/CardRemovedContent";

const isCardItemPromotionDriver = (
  driver: CardItemDriver,
): driver is CardItemPromotionDriver => {
  return (
    driver.contentType === CardType.PROMOTION ||
    driver.contentType === CardType.PROMOTION_REPOST
  );
};

interface AuthorData {
  name: string;
}

interface SourceData {
  name: string;
  icon: string;
  url: string;
}

export interface ICardItemContent {
  comment?: string;
  content: {
    id: string;
    title: string;
    url: string;
    source?: SourceData;
    authors: AuthorData[];
  };
}

export interface CardItemMetaData {
  starred: boolean;
}

export type CardItemDriver =
  | CardItemDraftDriver
  | CardItemMusicDriver
  | CardItemVideoDriver
  | CardItemArticleDriver
  | CardItemActionDriver
  | CardItemInfoDriver
  | CardItemEditableDriver
  | CardItemPromotionDriver;

interface LayoutDriver {
  readonly cardSize: CardSize;
  readonly cardLayout?: CardArticleLayoutType;
}

export type CardItemLocation =
  | "home"
  | "deck-detail"
  | "card-detail"
  | "profile"
  | "archive";

export interface CardItemProps {
  driver: CardItemDriver;
  layoutDriver: LayoutDriver;
  renderMenuContext(): ReactNode;
  renderSharing(): ReactNode;
}

export const CardItem = observer(function CardItem(props: CardItemProps) {
  const { driver, layoutDriver, renderMenuContext, renderSharing } = props;

  if (driver.contentType === CardType.ACTION) {
    return (
      <CardItemAction
        driver={driver}
        layoutDriver={layoutDriver}
        renderMenuContext={renderMenuContext}
      />
    );
  }

  if (driver.contentType === CardType.INFO) {
    return (
      <CardItemInfo
        driver={driver}
        layoutDriver={layoutDriver}
        renderMenuContext={renderMenuContext}
      />
    );
  }

  if (driver.contentType === CardType.EDITABLE) {
    return (
      <CardItemEditable
        driver={driver}
        layoutDriver={layoutDriver}
        renderMenuContext={renderMenuContext}
      />
    );
  }

  if (isCardItemPromotionDriver(driver)) {
    return <CardItemPromotion driver={driver} renderSharing={renderSharing} />;
  }

  return driver.cardType === MasterLinkType.REMOVED ? (
    <CardRemovedContent driver={driver} renderMenuContext={renderMenuContext} />
  ) : driver.isCardDrafting === true ? (
    <CardItemDraft driver={driver} renderMenuContext={renderMenuContext} />
  ) : driver.cardType === MasterLinkType.MUSIC ? (
    <CardItemMusic
      driver={driver}
      layoutDriver={layoutDriver}
      renderSharing={renderSharing}
      renderMenuContext={renderMenuContext}
    />
  ) : driver.cardType === MasterLinkType.VIDEO ? (
    <CardItemVideo
      driver={driver}
      layoutDriver={layoutDriver}
      renderSharing={renderSharing}
      renderMenuContext={renderMenuContext}
    />
  ) : (
    <CardItemArticle
      driver={driver}
      layoutDriver={layoutDriver}
      renderSharing={renderSharing}
      renderMenuContext={renderMenuContext}
    />
  );
});
